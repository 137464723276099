import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const frontendEngineer = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a senior Frontend Engineer. Apply to join our team!',
    },
    keywords: {
      keywords: 'Senior Frontend Engineer,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/6DIqOy4m0MTKalMuu4CMv3/53f43e9a3eaba9260c8662e3141c4032/frontend-engineer.jpeg',
      },
    },
    title: {
      title: 'Senior Frontend Engineer',
    },
  }

  const link = 'https://apply.workable.com/lensesio/j/93A12D73F2/'

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Senior Frontend Engineer</h1>
          <p className="meta">London, Munich, Athens, Madrid </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>

            <ul className="pl-4 ">
              <li>
                Take part in all stages of the development process, from
                requirements gathering to implementation and testing.
              </li>
              <li>
                Work in multidisciplinary teams and collaborate closely with
                neighbouring disciplines, such as UX Designers, BackEnd
                Developers, DevOps, Product Managers
              </li>
              <li>
                Participate in an Agile environment, with clear goals and fast
                releases.
              </li>
              <li>
                Take part in knowledge sharing sessions with the rest of the UI
                Engineers or in company-wide ones.
              </li>
              <li>
                Make use of the latest tools, libs and frameworks available in
                the Front-End space.
              </li>
              <li>Help build the DataOps platform of the future</li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                Motivated to deliver and improve his/her knowledge constantly
              </li>
              <li>
                Proven front end development experience, with a focus on
                building single-page web applications.
              </li>
              <li>
                Ability to write clean code and understand when a particular
                part of the code needs to be refactored, what type of design
                patterns to use, etc.
              </li>
              <li>
                Excellent knowledge of Javascript, from its closures, scopes and
                the later ES6+ features.
              </li>
              <li>
                Experience building design systems with Styled Components,
                Typescript and ReactJS.
              </li>
              <li>Advanced React concepts and patterns.</li>
              <li>
                Solid HTML &amp; CSS knowledge and ability to structure layouts
                with minimal and clear structure.
              </li>
              <li>
                Knowledge of the Front-End Development ecosystem, web tooling
                such as NPM, YARN, Webpack, etc.
              </li>
              <li>
                Know-how on ways to debug code, investigate performance issues,
                track issues, and fix them
              </li>
              <li>
                Some Experience with testing Frameworks such as JEST, React
                Testing Library and Cypress.
              </li>
              <li>Excellent spoken / written communication in English</li>
            </ul>

            <h5 className="pt-3">Good to have experience with:</h5>

            <ul className="pl-4 ">
              <li>Global State management Libraries. (such as Redux, MobX)</li>
              <li>Rx.js, Ramda</li>
              <li>Cypress or other E2E testing frameworks</li>
              <li>WebSockets or other type of streaming data</li>
              <li>
                Experience working with a live Component Library such as Fractal
                or Storybook
              </li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default frontendEngineer
